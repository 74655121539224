import IProps from "../../../../interfaces/IProps";
import Modal from 'react-modal';
import "./ModalRefund.css";
import { useEffect, useState } from "react";
import RestService from "services/RestService";
import { toast } from 'react-toastify';
import closeMark  from "../../../../assets/closeMark.png";

interface Props extends IProps {
    canShow: any;
    setCanShow: (e: any) => void;
    refundData: any;
    orderData: any;
    edit: any;
    refundsList: any,
    orderDetails: any,
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '30%',
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
};



const ModalPopUp = ({ canShow, setCanShow, refundData, orderData, edit, refundsList, orderDetails }: Props) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [reason, setReason] = useState([] as any);
    const [notes, setNotes] = useState('');
    const [payment, setPayment] = useState<any>([]);
    const [paymentEdit, setPaymentEdit] = useState<any>([]);
    const [reasonChosen, setSelectedReason] = useState();
    const [methodPayment, setSelectedPayment] = useState();
    const [itemsData, setItemsData] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [rmaNumber, setRmaNumber] = useState<any>();
    const [amount, setAmount] = useState<any>("");
    const [date, setDate] = useState<any>();
    const [cancelSubmitBoolean, setCancelSubmitBoolean] = useState<boolean>(true);
    const [refundStatus, setRefundStatus] = useState<any>("CREATED");
    const [showCancelledButton,setShowCancelledButton] = useState<boolean>(true);
    const [originalPayment, setOriginalPayment] = useState<any>('');
    const [refundedValue, setRefundedValue] = useState<number>(0)

    useEffect(() => {
        const successCallback = (response: any) => {
            setReason(response.reasons);
            setPayment(response.paymentTypes);
            setPaymentEdit([{text:"Original order payment method"},{text:"CHECK"}]);
        }
        const url = "/api/order-service/orders/config/refunds";
        RestService.getRequest(url, successCallback, (err) =>
            console.log(err));


        refundData && refundData.map((item: any) => {
            item.createRefund = false;
            return item;
        })
        setDataList(refundData);
    }, [dataList]);

    const statusOptions = [
        "CREATED", "CREDIT APPLIED", "REFUND APPLIED", "AUTHORIZATION REVERSAL", "NEED REVIEW","CANCELLED"
    ];

    useEffect(() => {
        let lineItemsArray: any;

        if (edit === true) {
            setCancelSubmitBoolean(false);
            setAmount(refundData[0].amount);
            setRmaNumber(refundData[0].rmaId);
            refundData[0].option = (refundData[0].refundStatus).toUpperCase();
            refundData[0].amountDetails = refundData[0].amount;
            refundData && refundData.map((item: any) => {
                lineItemsArray = item.itemsToReturn;
                item.createRefund = true;
                if(item.refundStatus == "CANCELLED"){
                    setShowCancelledButton(false); 
                }
                // item.amount = 0;
                return item;
            })
            setItemsData(lineItemsArray);
            setDataList(refundData);
        } else {
            setItemsData(orderData.items);
            if(orderData.payments == null){
                setOriginalPayment("ORIGINAL");
            } else  {
                setOriginalPayment((orderData.payments[0].method).toUpperCase().replace(/\s/g, ''));
            }
            
        }
    },[])

    useEffect(()=> {
        if(refundsList && refundsList?.length) {
            const refundValue = refundsList?.reduce((total: number, item : any) => {
                return total + item?.amount
            }, 0)
            if(orderDetails?.orderType?.toUpperCase() === 'STS') {
                setRefundedValue((orderDetails?.subTotal + orderDetails?.taxes + orderDetails?.totalInstallationFees) - refundValue)
            }else {
                setRefundedValue(orderDetails?.total - refundValue)
            }
        }else {
            if(orderDetails?.orderType?.toUpperCase() === 'STS') {
                setRefundedValue(orderDetails?.subTotal + orderDetails?.taxes + orderDetails?.totalInstallationFees)
            }else {
                setRefundedValue(orderDetails?.total)
            }
        }

    }, [orderDetails, refundsList])
    

    const handleEditChanges = (e: any, type: any) => {
        const error = [];
        
        if (type == "rma") { 
            if (!!e.target.value && e.target.value > 0) {
                setRmaNumber(e.target.value);
                !edit && (itemsData[0].rmaNumber = e.target.value);
                !!edit && (dataList[0].rmaNumber = e.target.value); 
                errorMessage.pop();
                validationCheck();                 
            } else {
                if(e.target.value == ""){
                    setRmaNumber("");
                    !edit && (itemsData[0].rmaNumber = "");
                    !!edit && (dataList[0].rmaNumber = ""); 
                    setCancelSubmitBoolean(false);
                    setErrorMessage([]);
                } 
                
            }
        } else if (type == "amount") {
            
            if (!!e.target.value && e.target.value != "" && e.target.value >= 0) { 
                !edit && (itemsData[0].amountDetails = e.target.value);
                !!edit && (dataList[0].amountDetails = e.target.value);
                setAmount(e.target.value);              
                setErrorMessage([]);
                validationCheck();
            } else {
                setAmount('');   
                setCancelSubmitBoolean(true);
                error.push("Amount required.");
                setErrorMessage(error);
            }


        }

    }

    const handleOnBlur = () => {
        const error = []
        if(amount > 0 && amount <= refundedValue) {
                !edit && (itemsData[0].amountDetails = amount);
                !!edit && (dataList[0].amountDetails = amount);
                setAmount(amount);              
                setErrorMessage([]);
                validationCheck();
            } else {
                setAmount('');   
                setCancelSubmitBoolean(true);
                if(amount > 0) {
                    error.push(`Please enter a valid refund amount up to the total outstanding amount ($${parseFloat(refundedValue+'').toFixed(2)}).`);
                } else {
                    error.push(`Amount required.`);
                }
                setErrorMessage(error);
        }
    }

    const onClear = () => {
        setCanShow(false);
        edit = false;
        setCancelSubmitBoolean(true);
        setErrorMessage([]);
        // dataList[0].amount = amount;
    }
    const selectedReason = (e: any) => {
        let error = [];
        setSelectedReason(e.target.value);
            !edit && (itemsData[0].reason = e.target.value);
            !!edit && (dataList[0].reason = e.target.value);
        if (!!e.target.value && e.target.value != "" && errorMessage.length == 0) {
                        
            validationCheck();
        } else {
            setCancelSubmitBoolean(true);
            error.push("Reason for refund required.");
            setErrorMessage(error);
        }
    }

    const selectedPayment = (e: any) => {
        let error = [];
        if(e.target.value == "Original order payment method"){
            setSelectedPayment(originalPayment);
            !edit && (itemsData[0].payment = originalPayment); 
        } else {       
            setSelectedPayment(e.target.value);
            !edit && (itemsData[0].payment = e.target.value);
        }

        if (!!e.target.value && e.target.value != "" && errorMessage.length == 0) {
           
            validationCheck();
        } else {
            setCancelSubmitBoolean(true);
            error.push("Payment method for refund required.");
            setErrorMessage(error);
        }
    }

    const selectOptions = (e: any) => {
        setRefundStatus(e.target.value);
        !!edit && (refundData[0].option = e.target.value);
        if (!!refundStatus && refundStatus != "" && errorMessage.length == 0) {           
       
            validationCheck();
        } else {
            setCancelSubmitBoolean(true);
        }
    }

    const validationCheck = () => {
        setCancelSubmitBoolean(true);
        if (!edit && !!itemsData?.[0]?.amountDetails && itemsData?.[0]?.amountDetails != 0 && itemsData?.[0]?.amountDetails != '' && !!itemsData?.[0]?.payment && itemsData?.[0]?.payment != ""  && !!itemsData?.[0]?.reason && itemsData?.[0]?.reason != "") {
            setCancelSubmitBoolean(false);
            setErrorMessage([]);
        } else if (!!edit && !!dataList?.[0]?.amountDetails && itemsData?.[0]?.amountDetails != 0 && dataList?.[0]?.amountDetails != '' && !!dataList?.[0]?.reason && dataList?.[0]?.reason != "" && !!dataList?.[0]?.option && dataList?.[0]?.option != "" ) {
            setCancelSubmitBoolean(false);
            setErrorMessage([]);
        } else {
            setCancelSubmitBoolean(true);
        }

    }




    const onSaveData = (cancel: any) => {
        const purchaseOrderNumber = orderData.orderNumber;
        if (edit) {
            const data = {
                "amount": parseFloat(dataList[0].amountDetails),
                "refundStatus": !!cancel ? 'CANCELLED' :refundStatus,
                "rmaId": parseInt(rmaNumber),
                "note": notes,
                "reason": dataList[0].reason,
                "closedDate": "2023-05-21"
            }
            const successCallback = () => {
                setCanShow(false);
                window.location.reload();
                toast.success("Refund data updated  successfully ", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-success'
                });

            };
            const errorCallback = (err: any) => {
                toast.error("Refund data updation failed ", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'toast-error'
                });
                console.log(err)
            }
            const putUrl = "/api/order-service/refunds/" + refundData[0].refundId + "?purchaseOrderNumber=" + refundData[0].purchaseOrderNumber;
            RestService.putRequest(putUrl, data, successCallback, (err) => errorCallback(err));

        } else {
            const data = {
                "amount": parseFloat(itemsData[0].amountDetails),
                "paymentType": methodPayment,
                "rmaId": parseInt(rmaNumber),
                "note": notes,
                "reason": reasonChosen,
                "closedDate": "2023-05-21"
            }
            const successCallback = (data: any) => {
                if (!!data.refundId) {
                    setCanShow(false);
                    refundData.push({ ...data });
                    window.location.reload();
                } else {
                    const error = [];
                    error.push(data.message);
                    setErrorMessage(error);
                }

            }
            const errorCallback = (err: any) => {
                console.log(err)
            }
            const url = "/api/order-service/refunds?purchaseOrderNumber=" + purchaseOrderNumber;
            RestService.postRequest(url, data, successCallback, (err) => errorCallback(err));
        }



    };


    return <Modal isOpen={canShow} style={customStyles} ariaHideApp={false}>
        <div>
            { !edit &&  <div>
            <h4 style={{ textDecorationLine: "underline" }}>Refund Request</h4>
            <span style={{ position: "absolute", right: "10px", top: "15px", display: "block", fontSize: "12px" }}>*Required Fields</span>
            </div> }
            {!!edit && <div className="returnsHeader">
                <h3 style={{ textDecorationLine: "underline", width:"100%", paddingTop:"0px" }}>Refund Request</h3>
                <img src = {closeMark} onClick={onClear}/>
                </div>}
            <ul className="containerUl">
               { !!edit && <li>
                    <div>
                        <h4> Reason for Refund*</h4>
                        <select className="dropDown" value = {refundData[0].reason} name="selectRefunds" onChange={(e) => selectedReason(e)} required>
                            {/* <option  selected>{}</option> */}
                            {reason.map(function (n: any) {
                                return (
                                    <>
                                        <option value={n['text']} >{n['text']}</option>
                                    </>
                                );
                            })}
                        </select>
                    </div>
                </li>}
                { !edit && <li>
                    <div>
                        <h4> Reason for Refund*</h4>
                        <select className="dropDown" name="selectRefunds" onChange={(e) => selectedReason(e)} required>
                            <option  selected disabled>{"Select a reason"}</option>
                            {reason.map(function (n: any) {
                                return (
                                    <>
                                        <option value={n['text']} >{n['text']}</option>
                                    </>
                                );
                            })}
                        </select>
                    </div> 
                </li>

                }
                {!edit && <li>

                    <div>
                        <h4>Payment Type*</h4>
                        <select className="dropDown" name="selectReturns" onChange={(e) => selectedPayment(e)} required>
                            <option disabled selected>{"Select an option "}</option>
                            {paymentEdit.map(function (n: any) {
                                return (
                                    <option value={n['text']}>{n['text']}</option>);
                            })}
                        </select>
                    </div>
                </li>}
                
                <li>
                    <div>
                        <h4>Associated RMA</h4>
                        <input style={{ width: "100%" }} value= {rmaNumber} type="number" onChange={(e) => handleEditChanges(e, "rma")} />
                    </div>
                </li>
                {!!edit && <li>
                    <div className="listItems">
                        <h4>Status</h4>
                        <select className="dropDown" value= {refundData[0].option} name="selectOptions" onChange={(e) => selectOptions(e)} required>
                            {/* <option selected >{}</option> */}
                            {statusOptions.map(function (n: any) {
                                return (<option value={n} >{n}</option>);
                            })}
                        </select>
                    </div>

                </li>}
                <li style={{ display: "flex" }}>
                    <div style={{ width: "50%", float: "left", marginBottom: "14px" }}>
                        <h4>Refund/Credit Amount*</h4>
                        <input type="text"  value = {amount} onChange={(e) => handleEditChanges(e, "amount")}  style={{ cursor: "default" }} onBlur={handleOnBlur}></input>
                    </div>
                    {/* <div style={{ width: "50%", float: "left", marginBottom: "14px" }}>
                        <h4>Closed Date</h4>
                        <input className="dateField" type="date" onChange={(e) => handleEditChanges(e, "date")} />
                    </div> */}
                </li>
                <li>
                    <div>
                        <h4>Notes</h4>
                        <textarea style={{ 'width': '100%', height: '70px' }} onChange={(e) => setNotes(e.target.value)} />
                    </div>
                </li>

                {errorMessage && <span style={{ color: "red", backgroundColor: "#fff", border: 0, fontSize: "12px" }}>{errorMessage}</span>}
            </ul>
            {/* { dataList && <CustomList
                columnHeadings={ColumnHeadings}
                listOfItems={dataList}
                totalNoPages={1}
                currentPage={1}
                changePage={() => { }}
                changeSortingOrder={() => { }}
            />} */}
            {
                (refundsList && refundsList?.length > 0) ?
                    <div>
                        <h4>Existing Refunds</h4>
                        <div className="refundModalTable">
                            {
                                (refundsList && refundsList?.length) && (
                                    <>
                                        <table className="refundTable">
                                            <thead>
                                                <tr className="refundTr">
                                                    <th><div>Refund created date</div></th>
                                                    <th><div>Refund reason</div></th>
                                                    <th><div>Refund amount</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    refundsList?.map((item: any, index: number) => {
                                                        return (
                                                            <tr key={`refundItem_${index}`}>
                                                                <td>{item?.closedDate}</td>
                                                                <td>{item?.reason}</td>
                                                                <td>$ {parseFloat(item?.amount).toFixed(2)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </>
                                )
                            }
                        </div>
                    </div> : null
            }
            <div style={{padding: '10px 0px 10px 0', display:"flex", flexDirection:"row",justifyContent:"flex-end", position:"sticky", bottom:0,backgroundColor:"#fff", width:"100%", height:"39px", borderTop:"1px solid #c1c1c1" }}>
            <button style={{ 'float': 'right', marginTop: "0px", marginRight: '0px', borderRadius: "5px" }} disabled={cancelSubmitBoolean} onClick={(e) =>{onSaveData(false)}}>Save</button>&nbsp;

           {!edit && <button style={{ 'float': 'right', marginTop: "0px", background: "#fff", color: "#0071BC", border: "1px solid #0071BC", borderRadius: "5px"}} onClick={onClear}>Close</button> }
            
            {!!showCancelledButton && !!edit && <button style={{ 'float': 'right', background: "red", color: "#fff", borderRadius: "5px", border: "1px solid red", width: "150px"}} onClick={(e) =>{onSaveData(true)}} >Cancel Refund</button>}
            </div>
            </div>
    </Modal>

};

export default ModalPopUp;