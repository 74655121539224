import SubSectionData from "interfaces/SubSectionData";
import React, { SetStateAction, useEffect, useState } from "react";
import ActionsSection from "./ActionsSection/ActionsSection";
import BookingActionsSection from "./BookingActionsSection/BookingActionsSection";
import "./OrderDetailsPage.css";
import OrderDetailsSection from "./OrderDetailsSection/OrderDetailsSection";
import TabularSection from "./TabularSection/TabularSection";
import RestService from "services/RestService";
import { useHistory, useParams } from "react-router-dom";
import TabularSectionData from "interfaces/TabularSectionData";
import CustomObjectType from "interfaces/CustomObjectType";
import ColumnHeadings from "interfaces/ColumnHeadings";
import { COLUMN_HEADINGS } from "utils/constants/OrderDetailsPageColumnHeadings";
import { toast } from "react-toastify";
import moment from "moment";
import ModalPopUp from "../OrderDetailsPage/ModalSection/ModalPopUP/ModalRefund";
import ModalPopUpReturns from "../OrderDetailsPage/TabularSection/ModalPopUP/ModalReturns";
import ModalMakePayments from "../OrderDetailsPage/TabularSection/ModalPopUP/ModalMakePayments";
import ShowBookingCreation from "./TabularSection/ModalPopUP/ShowBookingCreation";

interface noteData {
  readonly orderNumber: any;
  note: any;
  attachment: any;
}

function getNumberOfDays(start: string, end: string): number {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

function getDifferenceInDate(difference: number) {
  const now = new Date();

  now.setDate(now.getDate() + difference);

  return now;
}

function isShippingFee(productNumber: string): boolean {
  const shippingFeeNumbers = ["TB00001", "TB00002", "TB00003", "TB00004", "TB00005", "TB00006"];
  return shippingFeeNumbers.includes(productNumber);
}

const OrderDetailsPage = () => {
  let { id } = useParams<{ id: string }>();
  const [orderNumber, setOrderNumber] = useState<string>(id);
  const [itemsData, setItemsData] = useState<CustomObjectType[]>([]);
  const [supplierOrders, setSupplierOrders] = useState<CustomObjectType[]>([]);
  const [shippingData, setShippingData] = useState<CustomObjectType[]>([]);
  const [installData, setInstallData] = useState<CustomObjectType[]>([]);
  const [clickAndCollectData, setClickAndCollectData] = useState<
    CustomObjectType[]
  >([]);
  const [vehicleData, setVehicleData] = useState<CustomObjectType[]>([]);
  const [paymentsdata, setPaymentsData] = useState<CustomObjectType[]>([]);
  const [creditCardDetails, setCreditCardDetails] = useState<
    CustomObjectType[]
  >([]);
  const [errorDetails, setErrorDetails] = useState<CustomObjectType[]>([]);
  const [eventsData, setEventsData] = useState<CustomObjectType[]>([]);
  const [costsData, setCostsData] = useState<CustomObjectType[]>([]);
  const [notesdata, setNotesData] = useState<CustomObjectType[]>([]);
  const [documentsData, setDocumentsData] = useState<CustomObjectType[]>([]);
  const [data, setData] = useState<any>();
  const [newCNCData1, setNewCNCData1] = useState<any>();
  const [noteData, setNoteData] = useState<noteData>({
    orderNumber: id,
    note: "",
    attachment: "",
  });
  const [orderData, setOrderData] = useState<TabularSectionData>();
  const [orderType, setOrderType] = useState<string>("");
  const [estCollectionDate, setEstCollectionDate] = useState<any>();
  const [cncDiscount, setCncDiscount] = useState<string>();

  const [activeHeader, setActiveHeader] = useState<string>("Summary");
  const [activeOrderDetailHeader, setActiveOrderDetailHeader] =
    useState<string>("items");
  const [orderStatus, setOrderStatus] = useState<string>();
  const history = useHistory();
  const [locationKeys, setLocationKeys] = useState([]);

  const [installerId, setInstallerId] = useState("");
  const [installer, setInstaller] = useState<{name: string, phone: string, email: string}>();
  const [bookingReference, setBookingReference] = useState("");
  const [returnData, setReturnData] = useState<CustomObjectType[]>([]);
  const [refundData, setRefundData] = useState<CustomObjectType[]>([]);

  const [canShow, setCanShow] = useState(false);
  const [canShowReturns, setCanShowReturns] = useState(false);
  const [canPayment, setPayment] = useState(false);
  const [afterClose, setAfterClose] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showInstallBooking, setShowInstallBooking] = useState(false);

  const [selectedBookingStatus, setSelectedBookingStatus] = useState<string>(""); // initialise string for the booking status selection here
  const [bookingsData, setBookingsData] = useState<any>();
  const bookingStatusValues = [
    {
      propertyName: "BLANK",
      displayName: " ",
      id: "1",
    },
    {
      propertyName: "RESERVED",
      displayName: "Reserved",
      id: "2",
    },
    {
      propertyName: "CUSTOMER_CONFIRMED",
      displayName: "Customer Confirmed",
      id: "3",
    },
    {
      propertyName: "INSTALLER_CONFIRMED",
      displayName: "Installer Confirmed",
      id: "4",
    },
    {
      propertyName: "INSTALLATION_COMPLETE",
      displayName: "Installer Complete",
      id: "5",
    },
    {
      propertyName: "MISSED",
      displayName: "Installation Missed",
      id: "6",
    },
    {
      propertyName: "FULLY_BOOKED",
      displayName: "Fully Booked",
      id: "7",
    },
    {
      propertyName: "INSTALLER_CLOSED",
      displayName: "Installer Closed",
      id: "8",
    },
    {
      propertyName: "UNABLE_TO_INSTALL_TIRES",
      displayName: "Unable to Install Tires",
      id: "9",
    },
    {
      propertyName: "NO_ANSWER_FOLLOW_UP",
      displayName: "No Answer - Followup",
      id: "10",
    },
    {
      propertyName: "VOICE_MAIL_FOLLOW_UP",
      displayName: "Voicemail - Followup",
      id: "11",
    },
    {
      propertyName: "OTHER",
      displayName: "Other",
      id: "12",
    },
  ];
  const [saveCount, setSaveCount] = useState<number>(0);
  const [cancelCount, setCancelCount] = useState<number>(0);

  const cancelOrderColumnHeadings: ColumnHeadings[] = [
    {
      propertyName: "selected",
      displayName: "Selected",
      id: "11",
      internalPropertyName: "selected",
      filterOptions: [],
    },
    {
      propertyName: "cancelQuantity",
      displayName: "CancelQuantity",
      id: "12",
      internalPropertyName: "cancelQuantity",
      filterOptions: [],
    },
    {
      propertyName: "reason",
      displayName: "Reason",
      id: "13",
      internalPropertyName: "reason",
      filterOptions: [],
    },
    {
      propertyName: "notes",
      displayName: "Notes",
      id: "14",
      internalPropertyName: "notes",
      filterOptions: [],
    },
  ];
  useEffect(() => {
    getData();
  }, []);

  // useEffect(() =>{

  // },[])

  useEffect(() => {
    getData();
    getReturnsData();
    getRefundData();
  }, [orderNumber, orderStatus]);

  const getReturnsData = () => {
    let goods: any = [];
    const url = "/api/order-service/returns?purchaseOrderNumber=" + id;
    const successCallback = (dataR: any) => {
      dataR &&
        dataR["items"].map((data: any, index: any) => {
          data.action = "Edit";
          data.edit = false;
          data.itemsToReturn.map((goodsToReturn: any, i: any) => {
            if (goods.indexOf(goodsToReturn.lineItemId) === -1) {
              goods.push(goodsToReturn.lineItemId);
              goods.push("  ");
            } else {
            }
          });
          data.goodsToReturn = goods;
        });

      setReturnData(dataR["items"]);
    };
    RestService.getRequest(url, successCallback, (err) => console.log(err));
  };

  const getRefundData = () => {
    const url = "/api/order-service/refunds?purchaseOrderNumber=" + id;
    const successCallback = (data: any) => {
      data && (data = data.items);
      data.map((item: any) => {
        item.action = "Edit";
        item.createRefund = true;
        item.edit = false;
        item.reason = item.reasonValue;
        return item;
      });
      setRefundData(data);
    };
    RestService.getRequest(url, successCallback, (err) => console.log(err));
  };

  const normaliseData = (data: {
    items: {
      productNumber: string,
      productName?: string,
    }[],
    supplierOrderDetails: {
      supplierLineItemDetails: {
        productNumber: string,
        styleIdentifier: string,
        productDescription: string,
      }[]
    }[]
  }) => {
    data?.supplierOrderDetails?.forEach(orderDetails => {
      orderDetails?.supplierLineItemDetails?.forEach(lineItemDetails => {
        data.items = data?.items?.map(item => {
          const normalisedItem = {
            ...item
          }

          if (item.productNumber === lineItemDetails.productNumber) {
            normalisedItem.productName = lineItemDetails.productDescription ?
              lineItemDetails.productDescription :
              lineItemDetails.styleIdentifier;
          }



          if(isShippingFee(item.productNumber) ){
            normalisedItem.productName = 'SHIPPING_FEE';
          }

          return normalisedItem;
        })
      })
    })
  };

  const getData = () => {
    // history.push("/orders/" + orderNumber);
    const url = "/api/order-service/orders/get-details/" + orderNumber;
    const successCallback = (data: any) => {
      normaliseData(data);

      setData(data);

      // data.siblingPurchaseOrderNumber = "TBH602642CCDATD";
      setOrderType(data["orderType"]);
      for (let item of COLUMN_HEADINGS.installColumnHeadings) {
        if (Object.values(item).includes("bookingReferenceNumber")) {
          COLUMN_HEADINGS.installColumnHeadings.pop();
        }
      }

      if (orderType == "STS") {
        COLUMN_HEADINGS.installColumnHeadings.push({
          propertyName: "bookingReferenceNumber",
          displayName: "Booking Reference Number",
          id: "2",
          internalPropertyName: "bookingReferenceNumber",
          filterOptions: [],
        });
        const url =
          "/api/installer-service/management/installers/get-bookings?purchaseOrderNumber=" +
          data.orderNumber;
        const successCallback = (d: any) => {
          setInstallerId(d.installerId);
          setBookingReference(d.bookingReferenceNumber);
          data.install[0]["installerId"] = d.installerId;
          data.install[0]["bookingReferenceNumber"] = d.bookingReferenceNumber;

          if (d.installerId && d.bookingReferenceNumber) {
            const url = "/api/installer-service/management/installers/" + d.installerId + "/bookings/" + d.bookingReferenceNumber;
            const successCallback = (bookingsData: any) => {
              setBookingsData(bookingsData);
            };
            RestService.getRequest(url, successCallback, (err) => console.log(err));
          }
        };
        RestService.getRequest(url, successCallback, (err) => {
          toast.error("No Bookings found for purchaseOrderNumber provided", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-error",
          });
        });

        getStsOrderInstaller(data.install[0]);
      }

      if (data.install && data.install.length > 0) {
        let myDate = moment(
          data.install[0].estimatedReadyForCollectionDate
        ).format("DD MMM YYYY");
        setEstCollectionDate(myDate);
        setCncDiscount(data.install[0].cncDiscount);
      }
      if (
        data.paymentGroup == null ||
        data.paymentGroup.paymentMethod == "CARD"
      ) {
        setPayment(true);
      } else {
        setPayment(false);
      }
      setOrderStatus(data["orderStatus"]);
      setItemsData(data.items);
      setShippingData(data.shipping);
      setInstallData(data.install);
      setClickAndCollectData(data.install);

      setVehicleData(data.vehicle);
      setPaymentsData(data.payments);
      setCreditCardDetails([data.creditCardDetailsDto]);
      setErrorDetails(data.errorList);
      setCostsData(data.costs);
      setEventsData(data.events);
      setSupplierOrders(data.supplierOrderDetails);
      data.notes && data.notes.sort(function(a:any,b:any){
        return (new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf());
      });
      setNotesData(data.notes);
      setDocumentsData(data.documents);
    };

    //TODO: Manage console.error
    RestService.getRequest(url, successCallback, (err) => console.log(err));
  };

  const getStsOrderInstaller = (installer: {locationNumber: number | string}): void => {
    if (installer) {
      const successCallback = (response: any) => {
        if (response.length > 0) {
          setInstallerId(response[0]?.id);
          setInstaller(response[0]);
        }
      };
      const url =
        "/api/installer-service/installers?locationNumber=" +
        installer?.locationNumber;
      RestService.getRequest(url, successCallback, (err) => console.log(err));
    }
  }

  const tabularSectionDataList: TabularSectionData[] = [
    {
      heading: "Items",
      key: "items",
      columnHeadings: COLUMN_HEADINGS.itemsColumnHeadings,
      tableContent: itemsData,
    },
    {
      heading: "Supplier Orders",
      key: "supplierOrders",
      columnHeadings: [],
      tableContent: [],
      customContent: <div className="CustomList">
          {supplierOrders?.map((supplierOrder: CustomObjectType) => {
            return (
                <React.Fragment key={supplierOrder["id"]}>
                  <table>
                    <thead>
                    <tr>
                      <th>Order Number</th>
                      <th>Location Number</th>
                      <th>Order Status</th>
                      <th>Order Type</th>
                      <th>Supplier Identifier</th>
                      <th>Total Amount</th>
                      <th>Payment Method</th>
                      <th>Invoice Status</th>
                      <th>Paid By User Name</th>
                    </tr>
                    </thead>
                    <tbody>
                  <tr>
                    <td>{supplierOrder.orderNumber}</td>
                    <td>{supplierOrder.locationNumber}</td>
                    <td>{supplierOrder.orderStatus}</td>
                    <td>{supplierOrder.orderType}</td>
                    <td>{supplierOrder.supplierIdentifier}</td>
                    <td>{supplierOrder.totalAmount}</td>
                    <td>{supplierOrder.paymentMethod}</td>
                    <td>{supplierOrder.invoiceStatus}</td>
                    <td>{supplierOrder.paidByUserName}</td>
                  </tr>
                    </tbody>
                  </table>
                  <table>
                      <thead>
                        <tr>
                          <th>Product Number</th>
                          <th>Style Identifier</th>
                          <th>Product Description</th>
                          <th>Unit Cost Price</th>
                          <th>Quantity</th>
                          <th>Line Total</th>
                          <th>Shipped Quantity</th>
                          <th>Delivered Quantity</th>
                          <th>Line Item Status</th>
                        </tr>
                      </thead>
                      <tbody>
                      {supplierOrder.supplierLineItemDetails?.map((supplierLineItem: CustomObjectType) => {
                        return (
                        <tr key={supplierLineItem["id"]}>
                          <td>{supplierLineItem.productNumber}</td>
                          <td>{supplierLineItem.styleIdentifier}</td>
                          <td>{supplierLineItem.productDescription}</td>
                          <td>{supplierLineItem.unitCostPrice}</td>
                          <td>{supplierLineItem.quantity}</td>
                          <td>{supplierLineItem.lineTotal}</td>
                          <td>{supplierLineItem.shippedQuantity}</td>
                          <td>{supplierLineItem.deliveredQuantity}</td>
                          <td>{supplierLineItem.lineItemStatus}</td>
                        </tr>
                        );
                      })}
                      </tbody>
                    </table>
                <br/><br/><br/><br/><br/>
                </React.Fragment>
            );
          })}
      </div>
    },
    // {
    //   heading: "Shipping",
    //   key: "shipping",
    //   columnHeadings: COLUMN_HEADINGS.shippingColumnHeadings,
    //   tableContent: shippingData,
    // },

    ...(orderType === "CNC"
      ? [
          {
            heading: "Pick Up",
            key: "clickAndCollect",
            columnHeadings: COLUMN_HEADINGS.clickAndCollectColumnHeadings,
            tableContent: clickAndCollectData,
          },
        ]
      : [
          {
            heading: "Install",
            key: "install",
            columnHeadings: COLUMN_HEADINGS.installColumnHeadings,
            tableContent: installData.map(el  =>
              ({...el,
                bookingReferenceNumber: bookingReference,
                installerId,
                installerName: installer?.name || '',
                phone: installer?.phone || '',
                email: installer?.email || ''
              })),
          },
        ]),
    {
      heading: "Vehicle",
      key: "vehicle",
      columnHeadings: COLUMN_HEADINGS.vehicleColumnHeadings,
      tableContent: vehicleData,
    },
    {
      heading: "Payments",
      key: "payments",
      columnHeadings: COLUMN_HEADINGS.paymentsColumnHeadings,
      tableContent: paymentsdata,
    },
    // {
    //   heading: "Costs",
    //   key: "costs",
    //   columnHeadings: COLUMN_HEADINGS.costsColumnHeadings,
    //   tableContent: costsData,
    // },
    // {
    //   heading: "Events",
    //   key: "events",
    //   columnHeadings: COLUMN_HEADINGS.eventsColumnHeadings,
    //   tableContent: eventsData,
    // },
    /* OMTS-1182 suppress Notes tab*/
    ...(orderType !== "CNC"
      ? [
          {
            heading: "Notes",
            key: "notes",
            columnHeadings: COLUMN_HEADINGS.notesColumnHeadings,
            tableContent: notesdata,
          },
        ]
      : []),
    //  ...( data && activeHeader  === "Summary" ?[
    {
      heading: "Returns",
      key: "returns",
      columnHeadings: COLUMN_HEADINGS.returnColumnHeadings,
      tableContent: returnData,
    },
    // ]
    // :[]),
    //  ...( activeHeader  === "Finance" ?[]
    {
      heading: "Refund",
      key: "refund",
      columnHeadings: COLUMN_HEADINGS.refundColumnHeadings,
      tableContent: refundData,
    },
    // ]
    // :[]),
    // {
    //   heading: "Documents",
    //   key: "documents",
    //   columnHeadings: COLUMN_HEADINGS.documentsColumnHeadings,
    //   tableContent: documentsData,
    // },
  ];

  if (data && data.creditCardDetailsDto) {
    const data: any = {
      heading: "Credit Card",
      key: "cardDetails",
      columnHeadings: COLUMN_HEADINGS.creditCardColumnHeadings,
      tableContent: creditCardDetails,
    };
    tabularSectionDataList.splice(5, 0, data);
  }
  if (data) {
    const data: any = {
      heading: "Errors",
      key: "errors",
      columnHeadings: COLUMN_HEADINGS.errorColumnHeadings,
      tableContent: errorDetails,
    };
    tabularSectionDataList.push(data);
  }
  const subSectionDataList: SubSectionData[] = [
    {
      heading: "Summary",
      subSectionRowDataList: [
        { title: "Date", content: data && data["orderDate"] },
        { title: "Sales Channel", content: data && data["salesChannel"] },
        {
          title: "Order Type",
          content:
            (data && orderType !== "CNC" && data["orderType"]) ||
            (data && orderType === "CNC" && "Click & Collect"),
        },
        { title: "Order Source", content: data && data["orderSource"] },
        // { title: "Order Status", content: data && data['orderStatus'] },
        {
          title: "Order Delivery Mode",
          content:
            data && data["orderDeliveryMode"] ? data["orderDeliveryMode"] : "",
        },
        { title: "Tire protection plan", content: " " },
        // { title: "Method", content: " " },
        // {
        //   title: "Merchant Ref",
        //   content: " ",
        // },
      ],
    },
    {
      heading: "Order Details",
      subSectionRowDataList: [
        // { title: "Supplier Order Status", content: data && data['supplierOrderStatus'] },
        // { title: "Display Order Status", content: data && data['displayOrderStatus'] },
        { title: "Partner PO No", content: data && data["partnerPoNo"] },
        { title: "Parent Order No", content: data && data["parentOrderNo"] },
        { title: "Partner Order No", content: data && data["partnerOrderNo"] },
        data &&
          !!data.siblingPurchaseOrderNumber && {
            title: "Related PurchasingPower order",
            content: data && data["siblingPurchaseOrderNumber"],
          },
        {
          title: "Marketplace  Order No",
          content: data && data["marketplaceOrderNumber"],
        },
        { title: "Road hazard", content: data ? "" + data["roadHazard"] : "" },
        {
          title: "Has Wheels By Size",
          content: data ? "" + data["hasWheelsBySize"] : "",
        },
        {
          title: "Wheels By Size Disclaimer",
          content: data ? "" + data["wheelsBySizeDisclaimer"] : "",
        },

        // { title: "Stage", content: " " },
        // { title: "Assigned To", content: " " },
      ],
    },
    {
      heading: "Billing Address",
      subSectionRowDataList: [
        {
          title: "Name",
          content:
            data &&
            data.billingAddress.firstName + " " + data.billingAddress.lastName,
        },
        {
          title: "Address",
          content: data ? (
            data.billingAddress.addressLine2 ? (
              <div style={{ display: "inline" }}>
                {data.billingAddress.addressLine1},&nbsp;
                {data.billingAddress.addressLine2},&nbsp;
                {data.billingAddress.city},&nbsp;
                {data.billingAddress.zip}.
              </div>
            ) : (
              <div style={{ display: "inline" }}>
                {data.billingAddress.addressLine1},&nbsp;
                {data.billingAddress.city},&nbsp;
                {data.billingAddress.zip}.
              </div>
            )
          ) : (
            ""
          ),
        },
        { title: "Email", content: data && data.billingAddress.email },
        { title: "Phone", content: data && data.billingAddress.phone },
        // { title: "Mail Opt-In", content: " " },
      ],
    },
  ];

  if (data && orderType === "CNC") {
    subSectionDataList.push({
      heading: "Finance",
      subSectionRowDataList: [
        {
          title: "Subtotal",
          content:
            data && data["subTotal"]
              ? "$ " + data["subTotal"]
              : data["subTotal"],
        },
        {
          title: "Taxes",
          content: data && data["taxes"] ? "$ " + data["taxes"] : data["taxes"],
        },
        {
          title: "Tax status",
          content: data && data["taxStatus"] ?
            data["taxStatus"].toLowerCase().replace("_", " ") :
            data["taxStatus"]
        },
        {
          title: "Shipping",
          content:
            data && data["shippingCost"]
              ? "$ " + data["shippingCost"]
              : data["shippingCost"],
        },
        {
          title: "Discount",
          content:
            data && data["discount"]
              ? "$ " + data["discount"]
              : data["discount"],
        },
        {
          title: "CNC Discount",
          content: cncDiscount ? "$ " + cncDiscount : cncDiscount,
        },
        {
          title: "Special Pricing",
          content:
            data && data["specialPricing"]
              ? "$ " + data["specialPricing"]
              : data["specialPricing"],
        },
        {
          title: "Total",
          content: data && data["total"] ? "$ " + data["total"] : data["total"],
        },
        {
          title: "Installation Cost",
          content:
            data && data["totalInstallationFees"]
              ? "$ " + data["totalInstallationFees"]
              : data["totalInstallationFees"],
        },
        {
          title: "TPMS Fee",
          content:
            data && data["tpmsFee"] ? "$ " + data["tpmsFee"] : data["tpmsFee"],
        },
        { title: "Pay Status", content: data && data["paymentStatus"] },
      ],
    });
  }

  if (data && orderType !== "CNC") {
    subSectionDataList.push({
      heading: "Finance",
      subSectionRowDataList: [
        {
          title: "Subtotal",
          content:
            data && data["subTotal"]
              ? "$ " + data["subTotal"]
              : data["subTotal"],
        },
        {
          title: "Taxes",
          content: data && data["taxes"] ? "$ " + data["taxes"] : data["taxes"],
        },
        {
          title: "Tax status",
          content: data && data["taxStatus"] ?
            data["taxStatus"].toLowerCase().replace("_", " ") :
            data["taxStatus"]
        },
        {
          title: "Shipping",
          content:
            data && data["shippingCost"]
              ? "$ " + data["shippingCost"]
              : data["shippingCost"],
        },
        {
          title: "Discount",
          content:
            data && data["discount"]
              ? "$ " + data["discount"]
              : data["discount"],
        },
        {
          title: "Special Pricing",
          content:
            data && data["specialPricing"]
              ? "$ " + data["specialPricing"]
              : data["specialPricing"],
        },
        {
          title: "Total",
          content: data && data["total"] ? "$ " + data["total"] : data["total"],
        },
        {
          title: "Installation Cost",
          content:
            data && data["totalInstallationFees"]
              ? "$ " + data["totalInstallationFees"]
              : data["totalInstallationFees"],
        },
        {
          title: "TPMS Fee",
          content:
            data && data["tpmsFee"] ? "$ " + data["tpmsFee"] : data["tpmsFee"],
        },
        { title: "Pay Status", content: data && data["paymentStatus"] },
      ],
    });
  }

  if (data && data.shippingAddress && orderType !== "CNC") {
    subSectionDataList.push({
      heading: "Home Address",
      subSectionRowDataList: [
        {
          title: "Name",
          content:
            data &&
            data.shippingAddress.firstName +
              " " +
              data.shippingAddress.lastName,
        },
        {
          title: "Address",
          content: data ? (
            data.shippingAddress.addressLine2 ? (
              <div style={{ display: "inline" }}>
                {data.shippingAddress.addressLine1},&nbsp;
                {data.shippingAddress.addressLine2},&nbsp;
                {data.shippingAddress.city},&nbsp;
                {data.shippingAddress.zip}.
              </div>
            ) : (
              <div style={{ display: "inline" }}>
                {data.shippingAddress.addressLine1},&nbsp;
                {data.shippingAddress.city},&nbsp;
                {data.shippingAddress.zip}.
              </div>
            )
          ) : (
            ""
          ),
        },
        { title: "Email", content: data && data.shippingAddress.email },
        { title: "Phone", content: data && data.shippingAddress.phone },
      ],
    });
  }
  if (data && data.mobileInstallationAddress) {
    subSectionDataList.push({
      heading: "Mobile Installation Address",
      subSectionRowDataList: [
        {
          title: "Name",
          content:
            data &&
            data.mobileInstallationAddress.firstName +
              " " +
              data.mobileInstallationAddress.lastName,
        },
        {
          title: "Address",
          content: data ? (
            <div style={{ display: "inline" }}>
              {data.mobileInstallationAddress.addressLine1},&nbsp;
              {data.mobileInstallationAddress.addressLine2},&nbsp;
              {data.mobileInstallationAddress.city},&nbsp;
              {data.mobileInstallationAddress.zip}
            </div>
          ) : (
            ""
          ),
        },
        {
          title: "Email",
          content: data && data.mobileInstallationAddress.email,
        },
        {
          title: "Phone",
          content: data && data.mobileInstallationAddress.phone,
        },
      ],
    });
  }
  useEffect(() => {
    const supplierLineItems: any[] = [];
    itemsData?.map((cli: any, cliIndex: number) => {
      cli.supplierLineItems?.map((sli: any, sliIndex: number) => {
        sli.orderItemId = cli.orderItemId;
        supplierLineItems.push(sli);
      });
    });
    const items = {
      heading: "Items",
      key: "items",
      columnHeadings: COLUMN_HEADINGS.supplierLineItemColumnHeadings,
      tableContent: supplierLineItems,
    };
    items?.tableContent?.map((supplierLineItem: any, i: number) => {
      const checkedData = {
        ...supplierLineItem,
        selected: false,
        cancelQuantity: 0,
        replaceQuantity: 0,
        notes: "",
      };
      items.tableContent[i] = checkedData;
    });

    setOrderData(items);
  }, [itemsData]);

  const openModalMakePayment = () => {
    setShowPayment(true);
  };

  const createNote = (data: noteData) => {
    //setNoteData(data);

    const formData = new FormData();
    formData.append("orderNumber", data.orderNumber);
    formData.append("note", data.note);
    if (data.attachment) {
      formData.append("attachment", data.attachment);
    }

    const url = "/api/order-service/orders/notes/";

    const successCallback = (response: any) => {
      setNotesData([
        ...notesdata,
        {
          createdBy: response.createdBy,
          createdDate: response.createdDate,
          note: response.note,
          noteUrl: response.attachmentUrl,
        },
      ]);
    };

    fetch(url, {
      headers: {
        Authorization: "Bearer " + RestService.oauthAccessToken,
      },
      method: "POST",
      body: formData,
    })
      .then((result) => {
        if (result.status === 401) {
          window.location.reload();
        }

        if (result.status === 200) {
          toast.success("Created note successfully ", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-success",
          });
          getData();
        }
        return result.json();
      })
      .then((response) => {
        successCallback(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cancelOrder = () => {
    const url: string = "/api/order-service/cancel/" + id;

    const successCallback = (response: any) => {
      if (!!response.result) {
        getData();
        toast.success("Cancelled order successfully ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-success",
        });
      } else if (response.isManualCancellationRequired) {
        toast.error(
          "The order has been cancelled in OMS, but the supplier order could not be automatically cancelled.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-error",
          }
        );
      } else {
        toast.error("Cancelling order failed ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-error",
        });
      }
    };

    const errorCallback = (response: any) => {
      console.log(response);
    };

    RestService.postRequest(url, {}, successCallback, (err) =>
      errorCallback(err)
    );
    //TODO: Manage console.error
  };

  const compensationRequest = (data: any) => {
    data.orderNumber = id;
    const url = "/api/order-service/compensation/";
    const successCallback = (response: any) => {
      getData();
      toast.success("Compensation requested successfully ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-success",
      });
    };
    const errorCallback = (response: any) => {
      console.log(response);
      toast.error("Order compensation request failed ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-error",
      });
    };
    RestService.postRequest(url, data, successCallback, (err) =>
      errorCallback(err)
    );
    //TODO: Manage console.error
  };

  const returnRequest = (data: any) => {
    data.orderNumber = id;
    const url = "/api/order-service/return/";
    const successCallback = (response: any) => {
      setOrderNumber(response.result);
      toast.success("Return requested successfully ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-success",
      });
    };
    const errorCallback = (response: any) => {
      console.log(response);
      toast.error("Return request failed ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-error",
      });
    };
    RestService.postRequest(url, data, successCallback, (err) =>
      errorCallback(err)
    );
    //TODO: Manage console.error
  };

  const replacementRequest = (data: any) => {
    data.purchaseOrderNumber = id;
    const url = "/api/order-service/replacement/";
    const successCallback = (response: any) => {
      if (!!response.result) {
        setOrderNumber(response.result);

        toast.success("Replacement requested successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-success",
        });
      } else {
        toast.error("Replacement request failed", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-error",
        });
      }
    };
    const errorCallback = (response: any) => {
      console.log(response);
    };
    RestService.postRequest(url, data, successCallback, (err) =>
      errorCallback(err)
    );
    //TODO: Manage console.error
  };

  useEffect(() => {
    // if (activeHeader === "Home Address") {
    //   setActiveOrderDetailHeader("shipping");
    // } else
    if (activeHeader === "Finance") {
      setActiveOrderDetailHeader("payments");
    }
  }, [activeHeader]);
  useEffect(() => {
    // if (activeOrderDetailHeader === "shipping") {
    //   const header = subSectionDataList.find(
    //     (subSectionData) => subSectionData.heading === "Home Address") ? "Home Address" : activeHeader;
    //   setActiveHeader(header);
    // } else
    if (activeOrderDetailHeader === "payments") {
      setActiveHeader("Finance");
    }
  }, [activeOrderDetailHeader]);

  useEffect(() => {
    return history.listen(() => {
      // if (history.action === "POP") {
      //   history.push('/orders');
      // }
    });
  }, []);

  const handleInstallerFeeRefund = () => {
    const url = `/api/order-service/refunds/updateInstallerFees?purchaseOrderNumber=${data.orderNumber}`;
    const successCallback = () => {
      // call details API again for new updated data
      getData();
      toast.success("Installer Refund Updated Successfully ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-success",
      });
    };
    const errorCallback = (response: any) => {
      console.log(response);
      toast.error("Installer Refund Updation failed ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-error",
      });
    };
    RestService.putRequest(url, null, successCallback, (err) =>
      errorCallback(err)
    );
  };

  const handleChangeBookingStatus = (e: { target: { value: SetStateAction<string>; }; }) => {
    setSelectedBookingStatus(e.target.value)
    console.log('in handleChangeBookingStatus selectedBookingStatus  , e.target.value:',
      JSON.stringify(selectedBookingStatus),
      JSON.stringify(e.target.value));
  };

  const date = subSectionDataList
    .find((sectionDataList) => {
      const dataList = sectionDataList.subSectionRowDataList.find(
        (dl) => dl?.title === "Sales Channel" && dl?.content === "RAC"
      );

      return sectionDataList.heading === "Summary" && dataList;
    })
    ?.subSectionRowDataList.find(
      (rawDataList) => rawDataList?.title === "Date"
    );

  const dateDiff = date
    ? getNumberOfDays(date.content as string, new Date().toString())
    : undefined;

  const openBookingModal = () => {
    setShowInstallBooking(true);
  };


  const saveBooking = () => {
    const statusData = {
      bookingStatus: selectedBookingStatus,
    }
    if (statusData.bookingStatus !== "") {
      const url = "/api/installer-service/management/installers/" + installerId +
        "/bookings/" + bookingReference + "/status";

      const successCallbackSaveStatus = () => {
        toast.success("Saved booking status successfully ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success'
        });
        setSaveCount(saveCount + 1);
      }
      const errorCallbackSaveStatus = (responseStatus: any) => {
        console.log('error booking status:',responseStatus);
        toast.error("Saved booking status failed ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
      }

      RestService.putRequest(url, statusData, successCallbackSaveStatus, (errSaveStatus) => errorCallbackSaveStatus(errSaveStatus));
    }
  }

  const cancelBooking = () => {
    const data = {};
    const url = "/api/installer-service/management/installers/" + installerId + "/bookings/" + bookingReference + "/cancel";

    const successCallbackCancel = () => {
      toast.success("Cancelled booking successfully ", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'toast-success'
      });
      setCancelCount(cancelCount + 1);
    }
    const errorCallback = (response: any) => {
      const errorMessage = "The booking has been cancelled in OMS, but could not be cancelled with the installer. Please contact them to cancel the booking directly."
      if(response.status !== 200 && response.status !== 500){
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
      } else if(response.status == 500) {
        toast.error("Internal Server Error, cancel bookings.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error'
        });
      }
    }

    RestService.putRequest(url, data, successCallbackCancel, (err) => errorCallback(err));
  }

  return (
    <div className="OrderDetailsPage Page">
      <div className="OrderNumber">
        <b style={{ textDecorationLine: "underline" }}>
          Purchase Order : {orderNumber}
        </b>
        {data && <span className="Status">Order Status: {orderStatus}</span>}
      </div>
      {!data && <div className="loader"></div>}
      {data && (
        <div>
          <div className="Section">
            <OrderDetailsSection
              subSectionDataList={subSectionDataList}
              activeHeader={activeHeader}
              setActiveHeader={setActiveHeader}
            />

            <div className="bookingStatusAndActions">
              {(bookingsData && bookingsData.bookingStatus && bookingsData.bookingStatus !== 'CANCELLED') &&
                <div className="bookingStatus"> Booking Status:
                  <select value={selectedBookingStatus || bookingsData.bookingStatus} onChange={handleChangeBookingStatus} >
                    {bookingStatusValues.map((item: any) => (
                      <option key={item.id} value={item.propertyName}>
                        {
                          item.displayName
                        }
                      </option>
                    ))
                    }
                  </select>
                </div>
              }

              {bookingsData ? (bookingsData.bookingStatus && (bookingsData.bookingStatus !== 'CANCELLED') &&
                <div>
                  <BookingActionsSection
                    orderStatus={orderStatus}
                    orderData={orderData}
                    saveBooking={saveBooking}
                    cancelBooking={cancelBooking}
                    bookingsData={bookingsData}
                  />
                </div>
              ) : (bookingReference &&
                <div>
                  <p>Loading Booking Status ...</p>
                </div>
              )}

              {(bookingsData && bookingsData.bookingStatus === 'CANCELLED') && (
                <div>
                  <p>The booking has been cancelled</p>
                </div>
              )}
            </div>

            <div className="containerButtons">
              <ActionsSection
                orderStatus={orderStatus}
                noteData={noteData}
                createNote={createNote}
                orderData={orderData}
                cancelOrder={cancelOrder}
                compensationRequest={compensationRequest}
                returnRequest={returnRequest}
                replacementRequest={replacementRequest}
              />
              <div style={{ display: "flex" }} className="rrContainer">
                <button
                  className="refundButton"
                  onClick={() => setCanShow(true)}
                >
                  Request Refund
                </button>
                <button
                  className="returnButton"
                  onClick={() => setCanShowReturns(true)}
                >
                  Create RMA
                </button>
                {orderType === "STS" &&
                  data &&
                  canPayment &&
                  data.paymenRreferenceNumber == null && (
                    <button
                      className="makePaymentButtonOrder"
                      onClick={() => openModalMakePayment()}
                    >
                      Make Payment
                    </button>
                  )}

                {orderType === "STS" &&
                  canPayment &&
                  data?.paymenRreferenceNumber &&
                  !data?.isInstallationFeeRefunded && (
                    <button
                      className="installerRefundButton"
                      onClick={handleInstallerFeeRefund}
                    >
                      Installer Fees Refunded
                    </button>
                  )}

                {orderType === "STS" && !!data?.isCreateBookingEnabled && (
                  <button
                    className="installerRefundButton"
                    onClick={openBookingModal}
                  >
                    Create Booking
                  </button>
                )}
              </div>
            </div>

            {dateDiff && dateDiff <= 15 && (
              <p style={{ fontSize: 16, fontWeight: "bold" }}>
                RAC Order: Do not re-book install past{" "}
                {moment(getDifferenceInDate(15 - dateDiff)).format(
                  "YYYY-MM-DD"
                )}
              </p>
            )}
          </div>

          <TabularSection
            tabularSectionDataList={tabularSectionDataList}
            activeHeader={activeOrderDetailHeader}
            returnData={returnData}
            setActiveHeader={setActiveOrderDetailHeader}
            refundsList={refundData}
            orderDetails={data}
          />
        </div>
      )}

      <div>
        {data && canShow && (
          <ModalPopUp
            canShow={canShow}
            setCanShow={setCanShow}
            refundData={refundData}
            orderData={data}
            edit={false}
            refundsList={refundData}
            orderDetails={data}
          />
        )}
      </div>
      <div>
        {data && canShowReturns && (
          <ModalPopUpReturns
            canShow={canShowReturns}
            setCanShow={setCanShowReturns}
            returnData={returnData}
            orderData={data}
            edit={false}
            afterClose={false}
            setAfterClose={setAfterClose}
          />
        )}
      </div>
      <div>
        {data && showPayment && installerId && (
          <ModalMakePayments
            canShow={showPayment}
            setCanShow={setShowPayment}
            orderData={data}
            installerId={installerId}
            clickedFrom={"order"}
            installerLocation={installData[0].locationNumber}
          />
        )}
      </div>
      <div>
        {data && installerId && orderNumber && (
          <ShowBookingCreation
            canShow={showInstallBooking}
            setCanShow={setShowInstallBooking}
            installerId={installerId}
            orderNumber={orderNumber}
          />
        )}
      </div>
    </div>
  );
};

export default OrderDetailsPage;
